<template>
  <div class="v-tree">
    <ul v-if="value" style="padding: 0">
      <tree-item
        class="v-item"
        @remove="onRemove($event)"
        @change="onChange($event, index)"
        @rename="onRename($event, index)"
        @copy="onCopy($event, index)"
        @code="onCode($event, index)"
        v-for="(el, index) in value"
        :key="index"
        :el="el"
        :index="index"
        :canEdit="canEdit"
      ></tree-item>
      <tree-add-item v-if="canEdit" :el="value" @change="onChangeAddItem($event)"></tree-add-item>
    </ul>
    <s-editor-edit-field ref="editField" />
    <portal to="v-main"> </portal>
  </div>
</template>

<script>
import TreeItem from "@/views/tree/treeItem.vue";
import treeAddItem from "@/views/tree/treeAddItem.vue";
import _ from "lodash";
export default {
  components: {
    TreeItem,
    treeAddItem,
  },
  props: {
    value: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$root._drag_src_el = null;
  },
  methods: {
    onRemove: function (index) {
      this.$emit("remove", { i: index });
      this.$forceUpdate();
    },
    onChange: function (e, index) {
      this.$emit("change", { v: e, i: index });
      this.$forceUpdate();
    },
    onRename: function (e, index) {
      this.$emit("rename", { v: e, i: index });
      this.$forceUpdate();
    },

    async changeCode(e) {
      let val = _.get(this.value, e);
      let model = {};
      model.editField = "field";
      model.name = "field";
      model.type = "text";
      model.title = e;
      //const editField = { data: { field: val }, model: [model] };
      const editField = { data: { field: JSON.stringify(val) }, model: [model] };
      //console.log("refs", this.$refs);
      //return false;
      return this.$refs.editField.show(editField);
    },
    async onCode(e, index) {
      let res = await this.changeCode(e);
      if (res) {
        _.set(this.value, e, JSON.parse(res.value));
      }
    },
    onCopy: function (e, index) {
      this.$emit("copy", { v: e, i: index });
      this.$forceUpdate();
    },
    onChangeAddItem: function (e) {
      this.$emit("create", e);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.v-tree {
  position: relative;

  ul {
    padding: 0;
  }

  li {
    padding-left: 20px;
    list-style: none;
  }

  ul:hover,
  li:hover {
    cursor: pointer;
  }

  .folder {
    font-weight: bold;
  }

  i {
    color: var(--primary);
  }
}
.v-item {
}
</style>
